.selected-and-correct {
  background-color: #90ee90;
  border: 1px solid black;
  margin: 10px;
  padding: 30px;
  min-width: 75px;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  border-color: #b8b8b8;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}


.selected-and-wrong {
  background-color: #ee9090;
  border: 1px solid black;
  margin: 10px;
  padding: 30px;
  min-width: 75px;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  border-color: #b8b8b8;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.not-selected {
  background-color: #ececec;
  border: 1px solid black;
  margin: 10px;
  padding: 30px;
  min-width: 75px;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  border-color: #b8b8b8;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;

}