.flashcard-question {
  font-weight: 800;
  font-size: 35px;
  margin: 10px;
  color: #b454f1;
}

.flashcard-row-container {
  margin: 35px;
  display: flex;
  justify-content: center;
}