.game-title {
  font-size: 50px;
  margin: 20px;
  color: #fff;
  background-color: #ff69b4;
  font-weight: 700;
  border-radius: 10px;
}

.game-closing {
  font-size: 50px;
  margin: 20px;
  color: #b454f1;
}